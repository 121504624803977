import logo from './logo.svg';
import './App.css';

import { ChakraProvider } from '@chakra-ui/react'
import { 
  Text,
  Heading,
  Highlight,
  Container,
  Box,
  Wrap, 
  WrapItem,
  Image,
  AspectRatio,
  extendTheme,
  SimpleGrid,
  Button,
  useClipboard,
  useToast
} from '@chakra-ui/react'

import { ChatIcon, EmailIcon } from '@chakra-ui/icons'

const theme = extendTheme({
  styles: {
    global: (props) => ({
      body: {
        bg: 'black',
      }
    })
  },
})


function App() {

  const { onCopy:onCopyEmail, hasCopied:hasCopiedEmail } = useClipboard("kiran@blox.is");
  const { onCopy:onCopyTg, hasCopied:hasCopiedTg } = useClipboard("realkinando");
  const toast = useToast()

  return (
    <ChakraProvider theme={theme}>
      <Container maxW='container.xl' centerContent>
        <Wrap justify='center' align='center'>
          <WrapItem w={[360,600]}>
            <Box bg='black' color='white' padding={8}>
              <Image src='logo.png' height={8}/>
              <AspectRatio ratio={1}>
                <video autoPlay muted playsinline loop>
                  <source src="earthloop.mp4" type="video/mp4" />
                </video>
              </AspectRatio>
              <Heading size='xl' color='#fffad2'>
              experienced DeFi natives, bringing a superior financial system to the real world
              </Heading>
            </Box>
          </WrapItem>
          <WrapItem w={[360,550]}>
          <Box bg='black' color='white' padding={8}>
          <Heading fontSize='xl' align='right' color='#f2e1cb'>
          Building scalable DeFi products from scratch
          </Heading>
          <Text fontSize='lg' align='justify'>
            Bloxis combines novel research with our connections to big players in the wider ecosystem to ensure the highest level of integration - leading to liquidity & demand that translates into the best products. 
          </Text>
          <Box>
          <Wrap>
            <WrapItem>
          <Box bg='black' color='white' w={[220]}>
            <br/><br/>
            <Heading fontSize='2xl' color='#f2e1cb'>Notable Projects</Heading>
            <SimpleGrid columns={[3, null, 4]} align='right'>
                <Box padding={1}>
                  <a href='https://docs.arcadefi.xyz/overview/arcade-finance'>
                    <Image src='arcadeiconedited.png' objectFit='contain' width='64px' height='64px' opacity={0.8}/>
                  </a>
                </Box>
                <Box padding={1}>
                  <a href='https://www.poolshark.fi/'>
                    <Image src='poolshark.png' objectFit='contain' width='57px' height='57px' opacity={0.8}/>
                  </a>
                </Box>
                <Box padding={2}>
                  <a href='https://redacted.finance/'>
                    <Image src='redacted.png' objectFit='contain' width='50px' height='50px' opacity={0.8}/>
                  </a>
                </Box>
            </SimpleGrid>
        </Box>
        </WrapItem>
        <WrapItem>
        <Box bg='black' color='white' w={[250]} align='right'>
          <br/><br/>
          <Heading fontSize='2xl' color='#f2e1cb' align='right'>Contact</Heading>
          <Box height={2}></Box>
          <Text fontSize='sm'>Kiran Marcelline Gill Fernando</Text>
          <Text fontSize='xs'>Chief Executive Officer</Text>
          <Box height={2}></Box>
          <Button 
            leftIcon={<EmailIcon />} 
            variant='outline' size='sm' 
            colorScheme='yellow'
            onClick={()=>
              {
                onCopyEmail();
                toast(
                  {
                    title: 'Copied',
                    description:'email address copied to clipboard',
                    variant: 'subtle',
                    duration: 2000,
                    isClosable: true,
                  }
                )
            }}
            >
              kiran@blox.is
          </Button>
          <Box height={2}></Box>
          <Button 
            leftIcon={<ChatIcon />} 
            variant='outline' 
            size='xs' 
            colorScheme='telegram'
            onClick={()=>
              {
                onCopyTg();
                toast(
                  {
                    title: 'Copied',
                    description:'telegram handle copied to clipboard',
                    variant: 'subtle',
                    duration: 2000,
                    isClosable: true,
                  }
                )
            }}
            >
            tg : @realkinando
            </Button>
        </Box>
        </WrapItem>
          </Wrap>
          </Box>
          </Box>
          </WrapItem>
        </Wrap>
        <br/><br/>
          <Text color='white'>Copyright 2024</Text><br/>
      </Container>
    </ChakraProvider>
  );
}

export default App;
